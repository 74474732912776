/* eslint-disable */

import {ContentClient} from 'dc-delivery-sdk-js';
import axios from 'axios'
import { contentApi, hubName } from '../config/cms.json'

export default class edgeDancerApi {
    static async getAllFilteredResults(contentType, filters) {
        let settings = {"depth": "root", "pageSize": 12};
        let results = {"data": {"responses": []}};
        try {
            let contentListReturned = await this.getFilteredContentList(contentType, filters, settings);
            results.data.responses = contentListReturned.data.responses;
            while (Boolean(contentListReturned.data.page.nextCursor)) {
                settings = {"depth": "root", "pageSize": 12, "cursor": contentListReturned.data.page.nextCursor};
                contentListReturned = await this.getFilteredContentList(contentType, filters, settings);
                results.data.responses = results.data.responses.concat(contentListReturned.data.responses);
            }
            return results;
        } catch
            (e) {
            console.error(e)
        }
    }

    static getFilteredContentList(contentType, filters, settings = {"depth": "root", "pageSize": 12}) {
        const base = `https://${contentApi}/content/filter`;
        let filterBy = {
            "filterBy": [
                {
                    "path": "/_meta/schema",
                    "value": contentType
                }
            ].concat(filters),
            "parameters": {
                "depth": settings.depth,
                "format": "inlined"
            },
            "page": {
                "size": settings.pageSize,
            }
        }
        if (Boolean(settings.cursor))
            filterBy.page["cursor"] = settings.cursor
        return axios.post(base, filterBy);
    }

    static async getAllContentByType(contentType,sort="none") {
        let settings = {"depth": "root", "pageSize": 12};
        if (sort != 'none'){
            settings['sortKey']=sort.sortKey;
            settings['sortOrder']=sort.sortOrder;
        }
        let results = {"data": {"responses": []}};
        try {
            console.log(results)
            let contentListReturned = await this.getContentListByType(contentType, settings);
            results.data.responses = contentListReturned.data.responses;
            while (Boolean(contentListReturned.data.page.nextCursor)) {
                console.log("cursor="+contentListReturned.data.page.nextCursor)
                settings['cursor'] =  contentListReturned.data.page.nextCursor;
                contentListReturned = await this.getContentListByType(contentType, settings);
                results.data.responses = results.data.responses.concat(contentListReturned.data.responses);
            }
            return results;
        } catch
            (e) {
            console.error(e)
        }
    }

    static getContentListByType(type, settings = {"depth": "root", "pageSize": 12}) {
        const base = `https://${contentApi}/content/filter`;
        const filterBy = {
           "filterBy": [
                {
                    "path": "/_meta/schema",
                    "value": type
                }
            ],
            "parameters": {
                "depth": settings.depth,
                "format": "inlined"
            },
            "page": {
                "size": settings.pageSize,
            }
        }
        if (Boolean(settings.cursor))
            filterBy.page["cursor"] = settings.cursor
        if (Boolean(settings.sortKey))
            filterBy['sortBy']= {"key": "sortKey","order": "sortOrder"}
        return axios.post(base, filterBy);
    }

    static getChildNodes = (parentId, settings = {"depth": "all", "pageSize": "12"}) => {
        const base = `https://${contentApi}/content/filter`;
        const filterBy = {
            "filterBy": [
                {
                    "path": "/_meta/hierarchy/parentId",
                    "value": parentId
                }
            ],
            "parameters": {
            "depth": settings.depth,
                "format": "inlined"
        },
            "sortBy": {
                "key": "default",
                "order": "ASC"
            }
        };
        return axios.post(base, filterBy);
    };

    static getContentItem(id) {
        const client = new ContentClient({
            hubName: hubName
        });
        return client.getContentItemById(id);
    }

    static async getParent(contentItem) {
        if (this.hasParent(contentItem)) {
            return await this.getContentItem(contentItem['_meta'].hierarchy.parentId)
        } else return {}
    }

    //depth ==0 == all
    static async getParents(contentItem, depth = 0) {
        let contentParent = await edgeDancerApi.getParent(contentItem);
        contentParent = contentParent.body;
        contentParent['child'] = contentItem;
        depth--;
        if (this.hasParent(contentParent) && depth != 0) {
            return await this.getParents(contentParent, depth)
        } else
            return contentParent
    }

    static async getParentsUntil(contentItem, schema = []) {
        try {
            if (this.hasParent(contentItem)) {
                let contentParent = await edgeDancerApi.getParent(contentItem);
                contentParent = contentParent.body;
                contentParent['child'] = contentItem;
                if (this.hasParent(contentParent) && !schema.includes(contentParent["_meta"].schema)) {
                    return await this.getParentsUntil(contentParent, schema)
                } else if (schema.includes(contentParent["_meta"].schema)) {
                    return contentParent
                } else
                    return false
            }
            return false
        } catch
            (e) {
            console.error(e)
        }
    }

    static isHierarchy(contentItem) {
        if (Boolean(contentItem['_meta'].hierarchy))
            return true
        else
            return false
    }

    static hasParent(contentItem) {
        if (this.isHierarchy(contentItem)) {
            if (Boolean(contentItem['_meta'].hierarchy.parentId))
                return true
            else
                return false
        } else
            return false
    }

    static parentId(contentItem){
        if(this.hasParent(contentItem)) {
            return contentItem['_meta'].hierarchy.parentId
        }
        else return false
    }

    static deliveryId(contentItem){
        return contentItem['_meta'].deliveryId
    }

    static isChildOf(child,parent){
        return this.deliveryId(parent)=== this.parentId(child)
    }
}
