<template>
    <v-container fluid class="pb-12" :style="backgroundImageStyle" >
      <v-row v-if="header" class="pt-12" >
        <v-col  lg="1" md="0" sm="0" offset-lg="3" offset-mg="3" class="pa-0">
          <h2>{{header}}</h2>
          </v-col>
            <v-spacer></v-spacer>
      </v-row>
      <v-row class="pa-4 mb-2"  v-for="(performance, i) in eventsToDisplay"
             :key=i>
        <v-col  lg="1" md="0" sm="0" offset-lg="3" offset-mg="3" class="pa-0">
          <div class="performanceListYear"
               style="display: flex;justify-content: center;align-content: center;flex-direction: column"><span
              v-if="Boolean(performance.year)">{{ performance.year }}</span></div>
        </v-col>
        <v-col style="border-bottom:  1px solid #eeeeee"  lg="1" md="1" sm="2" class="pa-0">
          <div class="performanceList font-weight-black">{{ performance.shortDateDM }},</div>
          <div class="performanceList font-weight-black">{{ performance.shortDateY }}</div>
        </v-col>
        <v-col style="border-bottom:  1px solid #eeeeee;" lg="4" md="5" sm="5" offset-lg="-3" offset-mg="-3"  class="pa-0">
          <router-link style="text-decoration: none;color:black"
                       :to="{ name: route, params: { id:  performance.id}}">
            <div class="performanceList font-weight-black">{{ performance.fullLocation.path }}</div>
            <div class="performanceList">{{ performance.venue }}</div>
          </router-link>
        </v-col>
      </v-row>
    </v-container>
</template>

<script>

/* eslint-disable */
import Header from './Header'
import edgeDancerApi from '../edgedancer';
import VueMarkdown from 'vue-markdown'
import utils from '../utils'
import moment from 'moment'
import AmplienceImage from "@/components/AmplienceImage";
import {mapActions} from 'vuex'

export default {
  "name": 'TourTable',
  "props": ['id','eventsToDisplay','header',"backgroundImage"],
  "data": () => ({
  }),
  "components": {Header, VueMarkdown,AmplienceImage},
  "mounted": async function () {
    try {
      console.log("TourTable")
      console.log(this.id)
      console.log(this.eventsToDisplay)
    } catch
        (e) {
      console.error(e)
    }
  },
  "methods": {
    ...mapActions([
      'fetchLocations'
    ]),
  },
  "computed": {
    route: function(){
      return (this.id==="past")? "PerformanceDetail" : "FuturePerformanceDetail"
    },
    "backgroundImageStyle":function() {
      if (Boolean(this.backgroundImage)) {
        return            "  background-size: cover;"+
            "background-image:url('" + utils.amplienceImageUrl(this.backgroundImage, 800,) + ")"
      } else return ""
    }
  },
  "watch": {
  }
}

</script>

<style scoped>



.backgroundImage {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(181, 37, 50, 0.9);
  color: #fff;
  visibility: hidden;
  opacity: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.itemWrapper {
  position: relative;
  width: 100%;
  height: 100%
}
.performanceListYear {
  font-family: 'Futura PT Book', sans-serif;
  font-size: 50px;
  font-weight: 500;
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  opacity: 0.10;
  padding: 0;
  line-height: 0.5;
  top: -25px;
  left: -25px;
  width: 60px;
  height: 100%;
}


.performanceList {
  font-size: 14px;
  font-weight: 700;
  text-align: left;
  text-transform: uppercase;
}
</style>