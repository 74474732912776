import Vuex from 'vuex'
import Vue from 'vue'
import edgeDancerApi from '../edgedancer'
import edgedancer from "../edgedancer";
import { contentBaseUri } from '../../config/cms.json'

/* eslint-disable no-new */
Vue.use(Vuex)
/* eslint-disable */
export default new Vuex.Store({
  state: {
    locations: [],
    pageSettings:[],
    primaryNav:{},
    landingPages:[]
  },
  getters: {
    locationPath: (state) => (id) => {
      const location= state.locations.filter(function (item) {
        return item.id === id;
      })
      return location[0].path
    },
    location: (state) => (id) => {
      const location= state.locations.filter(function (item) {
        return item.id === id;
      })
      return location[0]
    },
    locations: (state) =>{
      return state.locations
    },
    LandingPages: (state) =>{
      return state.landingPages
    },
    LandingPage: (state) => (route) => {
      const setting = state.landingPages.find(function (page){
        return page.content.routeName === route;
      })
      return (Boolean(setting))?setting.content:false
    },
    pageSetting: (state) => (route) => {
      const routeWithoutParam = route.path.replace(route.params[Object.keys(route.params)[0]],'')
      console.log("route", route.path)
      console.log("routeWithoutParam", routeWithoutParam)
      const setting = state.pageSettings.find(function (item){
        const itemPath = item.content.routePath.replace(route.params[Object.keys(route.params)[0]],'')
        return itemPath === routeWithoutParam;
      })
      console.log(state);
      return (Boolean(setting))?setting.content:false
    },
    primaryNav: (state) =>{
      return state.primaryNav
    }
  },
  mutations: {
    setLocations (state, locations) {
      state.locations = locations
    },
    setPageSettings(state, pageSettings){
      state.pageSettings = pageSettings
    },
    setPrimaryNav(state, primaryNav){
      state.primaryNav = primaryNav
    },
    setLandingPages(state, landingPages){
      state.landingPages = landingPages
    }
  },
  actions: {

    async fetchLocations({commit,state,dispatch},{id}){
      let x=id;
      x++;
      if(state.locations.length==0) {
        const locationReferences = await Promise.all([
          edgeDancerApi.getAllContentByType(
            `${contentBaseUri}/bands/country`),
          edgeDancerApi.getAllContentByType(
            `${contentBaseUri}/bands/state`),
          edgeDancerApi.getAllContentByType(
            `${contentBaseUri}/bands/city`)
        ]);
        dispatch('buildLocationMap',locationReferences)
      }
    },
    async fetchPageSettings({commit,state},{id}){
      let x=id;
      x++;

      if(state.pageSettings.length==0) {
        const PageSettings = await edgeDancerApi.getAllContentByType(
          `${contentBaseUri}/site/page-settings`)
        commit('setPageSettings',PageSettings.data.responses)
      }
    },
    async fetchLandingPages({commit,state},{id}){
      let x=id;
      x++;
      if(state.pageSettings.length==0) {
        const PageSettings = await edgeDancerApi.getAllContentByType(
          `${contentBaseUri}/site/landing-page`)
        commit('setPageSettings',PageSettings.data.responses)
      }
    },
    async fetchPrimaryNavigation({commit,state,dispatch},{id}){
      try{
      let x=id;
      x++;
      if(!Boolean(state.primaryNav.items)) {
        const navLayers = await Promise.all([
          edgeDancerApi.getAllContentByType(`${contentBaseUri}/site/nav`),
          edgeDancerApi.getAllContentByType(`${contentBaseUri}/site/nav-item`),
          edgeDancerApi.getAllContentByType(`${contentBaseUri}/site/nav-group`)
        ])
        dispatch('buildNavigation', navLayers)
      }     } catch
          (e) {
        console.error(e)
      }
    },
    buildNavigation({commit},navLayers){
      let primaryNav = navLayers[0].data.responses.find(function (nav){
        return nav.content.Navigation === 'Primary';
      }).content
      primaryNav['sections'] = [];
      const sections= navLayers[2].data.responses
      const items= navLayers[1].data.responses
      for (let i = 0; i < sections.length; i++) {
        let sectionItems = items.filter(function (item) {
          return edgedancer.isChildOf(item.content, sections[i].content)
        });
        if (Boolean(sectionItems)) {
          sections[i].content['items']=sectionItems
          primaryNav['sections'].push(sections[i].content)
        }
      }
      commit('setPrimaryNav',primaryNav)
    },
    buildLocationMap({commit},locationReferences ) {
      let locations = []
      const countries=locationReferences[0].data.responses;
      const states=locationReferences[1].data.responses;
      const cities=locationReferences[2].data.responses;
      const usa = countries.filter(function (item) {
        return item.content['_meta'].deliveryId === states[0].content['_meta'].hierarchy.parentId;
      });
      for (let i = 0; i < cities.length; i++) {
        const location = states.filter(function (state) {
          return edgedancer.isChildOf(cities[i].content, state.content)
   //       return state.content['_meta'].deliveryId === cities[i].content['_meta'].hierarchy.parentId;;
        });
        if(Boolean(location.length>0)) {
          const usaState = {
            id: cities[i].content['_meta'].deliveryId,
            city: cities[i].content.city,
            cityId:cities[i].content['_meta'].deliveryId,
            stateId: location[0].content['_meta'].deliveryId,
            state: location[0].content.state,
            country: usa[0].content.country,
            countryId: usa[0].content['_meta'].deliveryId,
            path: cities[i].content.city + ", " + location[0].content.state + ", " + usa[0].content.country
          };
          locations.push(usaState);
        }
      }
      for (let i = 0; i < cities.length; i++) {
        const location = countries.filter(function (country) {
          return edgeDancerApi.isChildOf(cities[i].content, country.content)
          //return country.content['_meta'].deliveryId === cities[i].content['_meta'].hierarchy.parentId;;
        });
        if(Boolean(location.length>0)) {
          const country = {
            id: cities[i].content['_meta'].deliveryId,
            city: cities[i].content.city,
            cityId: cities[i].content['_meta'].deliveryId,
            countryId: location[0].content['_meta'].deliveryId,
            country: location[0].content.country,
            path: cities[i].content.city + ", " + location[0].content.country
          };
          locations.push(country);
        }
      }
        commit('setLocations',locations)
      }
    }
})
