<template>
  <v-row class="grey lighten-4 pt-6 px-sm-4 px-md-16 px-lg-16">
    <v-col cols="12" lg="2" md="2" sm="12" class="pa-0 ma-0">
      <div style="line-height:1;width:100%;text-align: left">Total performances</div>
      <div class="perfTotal" style="width:100%">{{ totalPerformances }}</div>
      <div>
        <div class="mt-lg-8 mt-md-8 mt-sm-2">First Performed</div>
        <router-link style="text-decoration: none;color:black" :to="{ name: 'PerformanceDetail', params: { id: performances[0]['_meta'].deliveryId}}">
        <div class="pastDate">{{ firstPerformance }}</div>
        <div class="pastCity">{{ performances[0].locationPath }}</div>
        </router-link>
      </div>
      <div class="mt-lg-8 mt-md-8 mt-sm-2" style="float:left">
        <router-link style="text-decoration: none;color:black" :to="{ name: 'PerformanceDetail', params: { id:  performances[totalPerformances - 1]['_meta'].deliveryId}}">
        <div>Latest Performance</div>
        <div class="pastDate">{{ lastPerformance }}</div>
        <div class="pastCity">{{ performances[totalPerformances - 1].locationPath }}</div>
        </router-link>
      </div>
    </v-col>
    <v-col cols="12" lg="10" md="10" sm="12" class="pa-0">
      <h2> Live Performances</h2>
      <v-container fluid class="">
        <v-row class="pa-4" style="border-bottom:  1px solid darkslategray;" v-for="(performance, i) in performances"
               :key=i>
          <v-col lg="2" md="2" sm="2" class="pa-0">
            <div class="performanceList">{{ performanceYear(performance)}}</div>
            <div class="performanceList">{{ performanceMonthDay(performance) }}</div>
          </v-col>
          <v-col lg="5" md="5" sm="5" class="pa-0">
            <router-link style="text-decoration: none;color:black" :to="{ name: 'PerformanceDetail', params: { id:  performance['_meta'].deliveryId}}">
            <div class="performanceList">{{ performance.locationPath }}</div>
            </router-link>
          </v-col>
          <v-col lg="5" md="5" sm="5" class="pa-0">
            <router-link style="text-decoration: none;color:black" :to="{ name: 'PerformanceDetail', params: { id:  performance['_meta'].deliveryId}}">
            <div class="performanceList">{{ performance.venue }}</div>
            </router-link>
          </v-col>

        </v-row>
      </v-container>
    </v-col>
  </v-row>
</template>

<script>
/* eslint-disable */
import edgeDancerApi from '../edgedancer';
import utils from '../utils'
export default {
  name: 'SongPerformances',
  props: ['performances'],
  data: () => ({
  }),
  components: {},
  mounted: async function () {
    try {
      console.log(this.performances);

    } catch
        (e) {
      console.error(e)
    }
  }
  ,
  methods: {

    performanceYear: function(performance){
      return utils.formatDate(performance.date, "YYYY")
    },
    performanceMonthDay: function(performance){
      return utils.formatDate(performance.date, "MMM DD")
    },
    performanceDate: function(performance){
      return utils.formatDate(performance.date, "MMM DD, YYYY")
    }
  },
  computed: {
    totalPerformances: function () {
      return this.performances.length
    },
    lastPerformance: function(){
      const lastPerfDate = utils.formatDate(this.performances[this.performances.length -1].date,"MMM DD,YYYY")
      return lastPerfDate
    },
    firstPerformance: function(){
    const firstPerfDate = utils.formatDate(this.performances[0].date,"MMM DD,YYYY")
    return firstPerfDate

    }
  }

}

</script>

<style scoped>

.pastDate {
  font-family: proxima-nova, sans-serif;
  font-size: calc(1vw + 10px);
  font-weight: 700;
  text-transform: uppercase;
}

.pastCity {
  font-family: proxima-nova, sans-serif;
  margin-top: 5px;
  font-size: calc(.9vw);
  font-weight: 600;
  text-transform: uppercase;
}

.perfTotal {
  font-size: 72px;
  font-weight: 700;
  text-align: left;
  line-height: 1;
}

.performanceList {
  font-size: 14px;
  font-weight: 700;
  text-align: left;
  text-transform: uppercase;
}
</style>