<template>
  <div v-if="loaded" class="full-width-block" >
    <div class="attrHeading">{{ setlist.content.title }}</div>
    <div class="setlist mb-6">
      <ul class="ma-0 pa-0">
        <li v-for="(item, index) in setlist.content.items"
            :key="index">
          <div class="list-number">{{item.content['sort-order']}}</div><div class="list-item">
          <router-link style="text-decoration: none;color:black" :to="{ name: 'SongDetail', params: { id: item.content.song.id||item.content.song._meta.deliveryId}}">{{ item.content.title }}</router-link>
        </div>
        </li>
      </ul>

    </div>
  </div>
</template>

<script>
/* eslint-disable */

import edgeDancerApi from "@/edgedancer";
import utils from "../utils"

export default {
  data() {
    return {
      loaded:false
    }
  },
  "mounted": async function () {
    try {
      console.log("setlist mounted");
      const setlistItems = await edgeDancerApi.getChildNodes(utils.getCid(this.setlist.content))
      this.setlist.content['items'] = setlistItems.data.responses
      this.loaded = true
    } catch
        (e) {
      console.error(e)
    }
  },


  props: ['setlist', 'backgroundColor', 'textColor'],
  methods: {
    "routerLinkToSong": function(item){
     return { "name": "songdetails", "params": { id: utils.getCid(item)}}
    }
  },
  components:{}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .full-width-block {
    width: 100%;
    background-image: none;
  }
  .headline {
    font-family: 'Futura Condensed PT Medium',sans-serif;
    font-weight: 500;
    font-size: calc(.9vw + 6px);
    line-height: 1;
    text-transform: uppercase;
    margin:1vw 1vw 2vw;
  }
  .list-number{
    font-weight: bold;
    display: inline-block;
    width:4vw;
    color: #aaaaaa
  }
  .list-item{
    position: relative;
    display: inline-block;
    cursor: pointer;

  }
  .setlist{

    text-align: left;
    min-width:350px;

  }
  li{
    font-family:  proxima-nova, sans-serif;
    font-weight: 600;
    font-size:14px;
    line-height: 1;

    list-style: none;
    border-bottom: 1px solid #e2e2e2;
    padding: 8px 0px;
    width: 100%;
  }
  ul{padding:0px}
</style>
