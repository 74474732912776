/* eslint-disable */

import moment from 'moment'
export default class utils {
  static formatDate(dateStr, format = "dd/mm/yyyy") {
    return moment(dateStr).format(format)
  }
  static sortDates (a, b) {
    return new moment(a.date).format('YYYYMMDD') - new moment(b.date).format('YYYYMMDD')
  }
  static sortDatesDesc (a, b) {
    return new moment(b.date).format('YYYYMMDD') - new moment(a.date).format('YYYYMMDD')
  }
  static dateIsFutureDate(a){
    return new moment(a.content.date).isAfter(new moment())
  }
  static dateIsPastDate(a){
    return new moment(a.content.date).isBefore(new moment())
  }
  static getCid(contentItem){
    return contentItem['_meta'].deliveryId
  }
  static getParentId(contentItem){
    return contentItem['_meta'].hierarchy.parentId
  }

  static amplienceImageUrl(src, w=250,h){
    const height=(Boolean(h))?"h="+h:""
   return 'https://' + src.defaultHost + '/i/' + src.endpoint + '/' + src.name +"?w="+w+"&"+height+"&fmt=auto"
  }
}

