
<template>
  <div>
  <picture width="0px">
    <source v-for="srcSetting in srcsets" :key="srcSetting.imageWidth"
            :srcset="'https://' + src.defaultHost + '/i/' + src.endpoint + '/' + encodeURIComponent(src.name )+ '?w=' + srcSetting.imageWidth + '&sm=aspect&aspect=' + srcSetting.aspectRatio+'&fmt=auto'"
            :media="'(min-width:' + srcSetting.displayMinWidth + 'px)'"/>
    <img  width="100%" :src="'https://' + src.defaultHost + '/i/' + src.endpoint + '/' + src.name + '?w='+ defaultImageWidth +'&sm=aspect&aspect=16:10&fmt=auto'" alt=""/>
   </picture>
  </div>
</template>
<script>
/* eslint-disable */

  export default {

    props:['src','srcsets','defaultImageWidth']
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
img{
  vertical-align:top
}
</style>
