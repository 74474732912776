<template>
  <v-container fluid v-if="loaded">
    <Header></Header>
    <v-row>

      <v-col cols="12" lg="3" md="3" sm="12" offset-lg="2" offset-mg="22">
        <Song :song="song"></Song>
      </v-col>
      <v-col cols="12" lg="5" md="5" sm="12" offset-lg="-2" offset-mg="-2">
        <youtube v-if="Boolean(song.body.heroVideo)" :video-id="song.body.heroVideo.link"></youtube>
        <SongReleases v-if="loaded" :releases="allReferences.releases"></SongReleases>
        <h2 style="width:100%" class="pl-4 pt-6" v-if="loaded">VIDEOS OF {{ song.body.title }}</h2>
        <VideoGallery v-if="Boolean(videos)" :videos="videos"></VideoGallery>
      </v-col>

    </v-row>

    <SongPerformances v-if="loaded" :performances="allReferences.performances"></SongPerformances>
  </v-container>
</template>

<script>

/* eslint-disable */
import edgeDancerApi from '../edgedancer';
import utils from '../utils'
import VueMarkdown from 'vue-markdown'
import AmplienceImage from './AmplienceImage'
import moment from 'moment'
import youtube from './youtube'
import VideoGallery from './VideoGallery'
import SongPerformances from './SongPerformances'
import SongReleases from './SongReleases'
import Song from './Song'
import {mapActions} from 'vuex'
import Header from './Header'
import { contentBaseUri } from '../../config/cms.json'
export default {
  "name": 'SongDetail',
  "props": ['id'],
  "data": () => ({
    "song": {},
    "allReferences": {},
    "contentId": "",
    "loaded": false

  }),
  "components": {Header, Song, SongReleases, SongPerformances, VideoGallery, youtube, VueMarkdown, AmplienceImage},
  "mounted": async function () {
    try {
      console.log("created SongDetail");
      await this.fetchLocations(2);
      const songReferences = await Promise.all([
        edgeDancerApi.getContentItem(this.id),
        edgeDancerApi.getAllFilteredResults(
            `${contentBaseUri}/bands/setlist-item`,
            {
              "path": "/song/id",
              "value": this.id
            })
      ])
      this.song = songReferences[0];
      this.allReferences = await this.getPerformancesReleasesParallel(songReferences[1].data.responses)
      console.log(this.allReferences);
      this.loaded = true;

    } catch
        (e) {
      console.error(e)
    }
  },
  "methods": {
    ...mapActions([
      'fetchLocations'
    ]),
    mapLocation: function (bandLocation) {
      return this.$store.getters.locationPath(bandLocation);
    },

    getPerformancesReleasesParallel: async function (setListItems) {
      try {
        let performances = [];
        let releases = [];
        const promises = [];

        for (let i = 0; i < setListItems.length; i++) {
          promises.push(
              edgeDancerApi.getParentsUntil(
                  setListItems[i].content,
                  [`${contentBaseUri}/bands/performance`, `${contentBaseUri}/bands/release`])
          )
        }
        let allParents = await Promise.all(promises)
        for (let i = 0; i < allParents.length; i++) {
          let reference = allParents[i]
          if (Boolean(reference)) {
            if (reference["_meta"].schema == `${contentBaseUri}/bands/performance`) {
              reference["locationPath"] = this.mapLocation(reference.location[0]["_meta"].deliveryId)
              performances.push(reference);
            } else if (reference["_meta"].schema == `${contentBaseUri}/bands/release`) {
              releases.push(reference);
            }
          }
        }
        performances = performances.sort(utils.sortDates);
        return {"performances": performances, "releases": releases};
      } catch
          (e) {
        console.error(e)
      }
    }
  },
  "computed":
      {
        "videos": function () {
          if (Boolean(this.song.body["videos-list"]))
            return this.song.body["videos-list"]
          else
            return false
        }
      }
}

</script>

<style scoped>
.author {
  font-family: 'Futura PT Book', sans-serif;
  margin-top: 5px;
  font-size: calc(.7vw + 7px);
  display: inline-block;
  text-transform: uppercase;
  width: 100%;
}

.pastDate {
  font-family: proxima-nova, sans-serif;
  font-size: calc(1vw + 10px);
  font-weight: 700;
  text-transform: uppercase;
}

.pastCity {
  font-family: proxima-nova, sans-serif;
  margin-top: 5px;
  font-size: calc(.9vw);
  font-weight: 600;
  text-transform: uppercase;
}

.lyrics {
  margin-top: 5px;
  font-size: 14px
}

.perfTotal {
  font-size: 72px;
  font-weight: 700;
  text-align: left;
  line-height: 1;
}

.performanceList {
  font-size: 14px;
  font-weight: 700;
  text-align: left;
  text-transform: uppercase;
}
</style>