
<template>
  <v-container fluid class="pa-0 pb-3 ma-0">

  <v-row class="pa-0 ma-0">
    <v-col lg="12" md="12" sm="12" class="pa-0 ma-0">
      <div style="position: absolute;width:100%;top:0px">

        <a href="/"><img style="tab-index: 100;  display: block;margin-left: auto;margin-right: auto;" :src="`https://${imageBasePath}/i/${accountName}/bandsite-logo?w=275`"></a>
      </div>
      <ResponsiveImage defaultImageWidth="1200"  :src="src" :srcsets="srcSet"></ResponsiveImage>
    </v-col>
  </v-row>
  <v-row class="pa-0 ma-0">
    <v-spacer></v-spacer>
    <v-col lg="6" md="6" sm="12" class="pa-0 ma-0">
      <div class="pageTitle">{{ pageTitle }}</div>
    </v-col>
    <v-spacer></v-spacer>
  </v-row>
  </v-container>
</template>
<script>
/* eslint-disable */

import ResponsiveImage from "@/components/ResponsiveImage";
import {mapActions} from "vuex";
import {mapGetters} from "vuex";
import { imageBasePath, accountName } from '../../config/cms.json';

export default {
  "data": () => ({
    "loaded": false,
    "src":{},
    "srcSet":[
        {'displayMinWidth':1900,'imageWidth':1900,'aspectRatio':'19:5'},
        {'displayMinWidth':1200,'imageWidth':1200,'aspectRatio':'19:6'},
        {'displayMinWidth':950,'imageWidth':950,'aspectRatio':'19:7'},
        {'displayMinWidth':600,'imageWidth':600,'aspectRatio':'16:8'},
        {'displayMinWidth':200,'imageWidth':320,'aspectRatio':'16:9'}
        ],
    "pageTitle":"",
    "imageBasePath": imageBasePath,
    "accountName": accountName
  }),
    props:['image','title'],
  "components": {ResponsiveImage},
  "mounted": async function () {
    try {
      this.configureHeader();
      this.loaded = true
    } catch
        (e) {
      console.error(e)
    }
  },
  "methods": {
    configureHeader: function () {
      const setting = this.pageSetting(this.$route)
      this.pageTitle=(Boolean(this.title))? this.title : setting.pageTitle
      this.src=(Boolean(this.image))? this.image : setting.masthead
    }
  },
  "computed":{
    ...mapGetters([ 'pageSetting'
    ]),
  },
  "watch":{
    $route (to, from){
      this.configureHeader();
    }
  }
  }

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
