<template>
  <v-container fluid v-if="loaded">
  <Header></Header>
    <v-row align="center"
           justify="center" v-if="id=='past'">
      <v-col lg="8" md="8" sm="12" offset-lg="3" offset-mg="3" class="pa-0">
        <div class=""
             style="display: flex;justify-content: center;align-content: center;flex-direction: column;height:45px;width:80px;float:left">
          Showing:
        </div>
        <div style="width:200px;float:left">
          <v-select
              class="d-flex align-center"
              v-model="selectedTour"
              :items="toursToDisplay"
              label="Tours"
              @change="selectTour"
              item-value="id"
              item-text="year"
              solo
              return-object
          ></v-select>
        </div>
      </v-col>
      <v-spacer></v-spacer>
    </v-row><!--
    <v-container fluid class="">
      <v-row class="pa-4"  v-for="(performance, i) in eventsToDisplay"
             :key=i>
        <v-col  lg="1" md="0" sm="0" offset-lg="3" offset-mg="3" class="pa-0">
          <div class="performanceListYear"
               style="display: flex;justify-content: center;align-content: center;flex-direction: column"><span
              v-if="Boolean(performance.year)">{{ performance.year }}</span></div>
        </v-col>

        <v-col style="border-bottom:  1px solid #eeeeee"  lg="1" md="1" sm="2" class="pa-0">
          <div class="performanceList font-weight-black">{{ performance.shortDateDM }},</div>
          <div class="performanceList font-weight-black">{{ performance.shortDateY }}</div>
        </v-col>
        <v-col style="border-bottom:  1px solid #eeeeee;" lg="4" md="5" sm="5" offset-lg="-3" offset-mg="-3"  class="pa-0">
          <router-link style="text-decoration: none;color:black"
                       :to="{ name: route, params: { id:  performance.id}}">
            <div class="performanceList font-weight-black">{{ performance.fullLocation.path }}</div>
            <div class="performanceList">{{ performance.venue }}</div>
          </router-link>
        </v-col>

      </v-row>
    </v-container>-->
    <TourTable :id="id"  :eventsToDisplay="this.eventsToDisplay"></TourTable>
  </v-container>
</template>

<script>

/* eslint-disable */
import Header from './Header'
import edgeDancerApi from '../edgedancer';
import VueMarkdown from 'vue-markdown'
import utils from '../utils'
import moment from 'moment'
import TourTable from "./TourTable"
import {mapActions} from 'vuex'
import { contentBaseUri } from '../../config/cms.json'

export default {
  "name": 'ToursLister',
  "props": ['id'],
  "data": () => ({
    "selectedTour": {},
    "allTours": [],
    "allPerformances": [],
    "allEvents": [],
    "eventsToDisplay": [],
    "toursToDisplay": [],
    "loaded": false
  }),
  "components": {TourTable,Header, VueMarkdown,},
  "mounted": async function () {
    try {
      const results = await this.fetchData();
      await this.fetchLocations(2);
      this.allPerformances = results[1].data.responses;
      this.allTours = results[0].data.responses;
      this.refreshDisplayItems(this.id);
      this.loaded = true
    } catch
        (e) {
      console.error(e)
    }
  },
  "methods": {
    ...mapActions([
      'fetchLocations'
    ]),
    refreshDisplayItems(tour){
      if (tour == "past") {
        this.allEvents = this.mapPerformancesToTours(this.allTours, this.getPastPerformances(this.allPerformances))
        this.eventsToDisplay = this.prepareEventForDisplay(this.allEvents.slice(0, 24))
        this.selectedTour = {id: "all", year: "all"}
        this.toursToDisplay = this.prepareTourForDisplay(this.allTours)
      } else {
        this.allEvents = this.mapPerformancesToTours(this.allTours,  this.getFuturePerformances(this.allPerformances))
        this.eventsToDisplay = this.prepareEventForDisplay(this.allEvents.slice(0, 24))
        this.selectedTour = {id: "all", year: "all"}
        this.toursToDisplay = this.prepareTourForDisplay(this.allTours)
      }
    },
    fetchData: async function () {
      return Promise.all([
        edgeDancerApi.getAllContentByType(`${contentBaseUri}/bands/tour`),
        edgeDancerApi.getAllContentByType(`${contentBaseUri}/bands/performance`)]
      )
    },

    getFuturePerformances: function (performances) {
      const events = performances.filter(utils.dateIsFutureDate)
      return events
    },
    getPastPerformances: function (performances) {
      const events = performances.filter(utils.dateIsPastDate)
      return events
    },
    performanceDate: function (performance) {
      return utils.formatDate(performance.date, 'MMMM, D, YYYY')
    },
    mapPerformancesToTours: function (tours, performances) {
      let events = [];
      for (let i = 0; i < performances.length; i++) {
        const tourEvent = tours.filter(function (item) {
          return item.content['_meta'].deliveryId === performances[i].content['_meta'].hierarchy.parentId;
          ;
        });
        if (Boolean(tourEvent.length > 0)) {
          const event = {
            id: utils.getCid(performances[i].content),
            performance: performances[i].content,
            tourId: utils.getCid(tourEvent[0].content),
            tour: tourEvent[0].content,
            year: tourEvent[0].content.year,
            cityId: performances[i].content.location[0].id,
            venue: performances[i].content.venue,
            date: performances[i].content.date
          };
          events.push(event);
        }
      }
      events = events.sort(utils.sortDatesDesc)
      return events;
    },
    prepareEventForDisplay: function (events) {
      const displayEvents = [];
      let displayYear = "";
      for (let i = 0; i < events.length; i++) {
        let displayEvent = {
          id: events[i].id,
          date: utils.formatDate(events[i].date, "MMM DD, YYYY"),
          shortDateY: utils.formatDate(events[i].date, "YYYY"),
          shortDateDM: utils.formatDate(events[i].date, "MMM DD"),
          venue: events[i].venue,
          fullLocation: this.$store.getters.location(events[i].cityId),
        };
        if (displayYear != events[i].year) {
          displayEvent['year'] = events[i].year;
          displayYear = events[i].year;
        }
        displayEvents.push(displayEvent);
      }
      return displayEvents;
    },
    prepareTourForDisplay: function (tours) {
      let displayTours = [];
      let displayYear = "";
      for (let i = 0; i < tours.length; i++) {
        displayTours.push({
          id: utils.getCid(tours[i].content),
          year: tours[i].content.year,
        });
      }
      displayTours = displayTours.sort(function (a, b) {
            if (a.year > b.year) {
              return -1;
            }
            if (a.year < b.year) {
              return 1;
            }
            return 0;
          }
      )
      displayTours.unshift({id: "all", year: "all"});
      return displayTours;
    },
    selectTour: function () {
      const selectedTourId = this.selectedTour.id
      if (selectedTourId == "all") {
        this.eventsToDisplay = this.prepareEventForDisplay(this.allEvents.slice(0, 24))
      } else {
        let events = this.allEvents.filter(function (item) {
          return item.tourId === selectedTourId;
        });
        this.eventsToDisplay = this.prepareEventForDisplay(events)
      }
    }
  },
  "computed": {
    route: function(){
      return (this.id==="past")? "PerformanceDetail" : "FuturePerformanceDetail"
    }
  },
  "watch": {
    id: function(newTour, oldTour) {
      return this.refreshDisplayItems(newTour)
    }
  }
}

</script>

<style scoped>


.performanceListYear {
  font-family: 'Futura PT Book', sans-serif;
  font-size: 50px;
  font-weight: 500;
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  opacity: 0.10;
  padding: 0;
  line-height: 0.5;
  top: -25px;
  left: -25px;
  width: 60px;
  height: 100%;
}


.performanceList {
  font-size: 14px;
  font-weight: 700;
  text-align: left;
  text-transform: uppercase;
}
</style>