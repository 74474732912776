<template>
  <v-container fluid v-if="loaded">
    <Header></Header>
    <v-row>
      <v-spacer></v-spacer>
      <v-col cols="12" lg="5" md="7" sm="12">
    <div v-for="value in songsToDisplay" :key="value.letter">
      <div class="letterHeading">{{ value.letter }}</div>
      <ul class="songList pa-0  mb-8 mt-5">
      <li class="song pa-0 ma-0" v-for="song in value.songs" :key="song.title">
        <router-link style="text-decoration: none;color:black" :to="{ name: 'SongDetail', params: { id:  song['_meta'].deliveryId}}">
          {{ song.title }}
        </router-link>
      </li>
      </ul>
    </div></v-col>
      <v-spacer></v-spacer>
    </v-row>
  </v-container>
</template>

<script>

/* eslint-disable */
import edgeDancerApi from '../edgedancer';
import utils from '../utils'
import {mapActions} from 'vuex'
import Header from './Header'
import { contentBaseUri } from '../../config/cms.json'
export default {
  "name": 'SongLister',
  "props": ['id'],
  "data": () => ({
    "allSongs": [],
    "songsToDisplay": {},
    "loaded": false,
    "test": {"a": "1", "b": "2"}
  }),
  "components": {Header},
  "mounted": async function () {
    try {
      await this.fetchLocations(2);
      const songList = await edgeDancerApi.getAllContentByType(`${contentBaseUri}/bands/song`)
      this.allSongs = songList.data.responses
      this.songsToDisplay = this.mapSongsToLetters(songList.data.responses)
      this.loaded = true
    } catch
        (e) {
      console.error(e)
    }
  },
  "methods": {
    ...mapActions([
      'fetchLocations'
    ]),

    mapSongsToLetters: function (songs) {
      songs = this.sortSongs(songs);
      let songMappings = [];
      let currentFirstChar = "";
      for (let i = 0; i < songs.length; i++) {
        if (songs[i].content.title.charAt(0) != currentFirstChar) {
          currentFirstChar = songs[i].content.title.charAt(0)
          const newCharArr = {letter: currentFirstChar, songs: []};
          songMappings.push(newCharArr)
        }
        songMappings.find(c => c.letter === currentFirstChar).songs.push(songs[i].content)
      }
      return songMappings;
    },
    sortSongs(songs) {
      const sortedSongs = songs.sort(function (a, b) {
        if (a.content.title < b.content.title) {
          return -1;
        }
        if (a.content.title < b.content.title) {
          return 1;
        }
        return 0;
      })
      return sortedSongs
    }
  },
  "computed": {}

}

</script>

<style scoped>
.letterHeading {
  font-size: 20px;
  font-weight: 700;
  border-bottom: 2px solid #000;
}
.song {
  font-family: proxima-nova,sans-serif;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  text-decoration: none;
  color:black;
}
.songList{
  column-count:2;
  list-style-type:none;
}
</style>