<template>
  <v-container fluid class="pa-0 pt-3 ma-0">
      <div
          v-for="(video, i) in videos"
          :key="i"
          class="grid-item"
      >
        <div>
        <youtube  :video-id="video.link"/>
        </div>
      </div>

  </v-container>
</template>

<script>
/* eslint-disable */
import youtube from './youtube'

export default {
  data() {
    return {};
  },
  props: ['videos'],
  components: {
    youtube
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.grid-item {
  width:50%;float: left
}
.grid-item:nth-child(odd){
  padding:  0px 6px 6px 0px;;

}
.grid-item:nth-child(even){
  padding: 0px 0px 6px 6px;
}

.video-thumb {
  width: 400px;
  border: black;
}
</style>
