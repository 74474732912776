<template>
  <div style="width: 100%" >
    <h2>{{ song.body.title }}</h2>
    <div class="author">Written by: {{ writers }}</div>
    <div class="lyrics">
      <VueMarkdown>{{ song.body.lyrics }}</VueMarkdown>
    </div>
  </div>
</template>

<script>

/* eslint-disable */
import edgeDancerApi from '../edgedancer';
import VueMarkdown from 'vue-markdown'

export default {
  "name": 'SongDetail',
  "props": ['song'],
  "data": () => ({
  }),
  "components": {VueMarkdown},
  "mounted": async function () {
    try {
      console.log("Song created");
    } catch
        (e) {
      console.error(e)
    }
  },
  "methods": {},
  "computed": {
    "writers": function () {
      const writers = this.song.body.writers;
      let writerNames = "";
      for (let i = 0; i < writers.length; i++) {
        if (i !== 0) {
          writerNames += ' / ';
        }
        writerNames += writers[i]
      }
      return writerNames
    }
  }
}

</script>

<style scoped>
.author {
  font-family: 'Futura PT Book', sans-serif;
  margin-top: 5px;
  font-size: calc(.7vw + 7px);
  display: inline-block;
  text-transform: uppercase;
  width: 100%;
}

.lyrics {
  margin-top: 5px;
  font-size: 15px
}

</style>