<template>
  <v-app>
    <v-app-bar
        text-black
        color="white"
        short
    >
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title
          class="pa-0"
          style="cursor:pointer;font-weight: 700;font-size: 18px"
          @click.stop="drawer = !drawer">Menu</v-toolbar-title>
    </v-app-bar>

    <v-navigation-drawer
        v-model="drawer"
        absolute
    >
      <v-list
          nav
          dense
      >
        <v-list-item-group  v-model="group" active-class="deep-purple--text text--accent-4">
          <div v-for="(section, i) in primaryNav.sections" :key="i">
            <v-subheader class="pa-0 ma-0 font-weight-black">{{section.sectionName}}</v-subheader>
            <v-divider class="pa-0 ma-0"></v-divider>
            <v-list-item  v-for="(item, j) in section.items"
                          :key="j"
                @click="$router.push({ name: item.content.routeName, params: { id: item.content.routeParameter}}).catch(()=>{})">
              <v-list-item-title style="text-decoration: none;color:black">{{item.content.name}}
              </v-list-item-title>
            </v-list-item>
          </div>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <v-main v-if="loaded" class="pa-0 pb-3 ma-0">
      <router-view class="pa-0 pb-3 ma-0"/>
      <img style="width:100%" :src="`https://${imageBasePath}/i/${accountName}/bandsite-footer-background`">
    </v-main>
  </v-app>
</template>

<script>
import {mapActions} from 'vuex'
import {mapGetters} from 'vuex'
import { imageBasePath, accountName } from '../config/cms.json'

export default {
  name: 'App',
  "mounted": async function () {
    await this.fetchPageSettings(2);
    await this.fetchPrimaryNavigation(2);
    this.loaded = true
  },
  components: {},
  data: () => ({
    //menu
    drawer: false,
    group: null,
    loaded: false,
    "imageBasePath": imageBasePath,
    "accountName": accountName
  }),
  computed: {
    ...mapGetters([
      'primaryNav'
    ])
  },
  methods: {
    ...mapActions([
      'buildLocationMap',
      'fetchPageSettings',
      'fetchPrimaryNavigation'
    ])
  },
  watch: {
    group() {
      this.drawer = false
    }
  }
}
</script>

<style>

body {
  border: 0;
  margin: 0;
  left: 5%;
  top: 0;
  width: 100%;
}


#app {
  font-family: Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  left: 5%;
  top: 0;
  width: 100%;
}

h2 {
  font-family: 'Futura Condensed PT Medium', sans-serif;
  font-weight: 500;
  font-size: calc(18px + 1.3vw);
  text-transform: uppercase;
  width: 100%;
}

.pageTitle {
  font-family: 'Futura Condensed PT Medium', sans-serif;
  font-weight: 500;
  font-size: calc(32px + 1.3vw);
  text-transform: uppercase;
  line-height: 0.7;
  opacity: 0.18;
  width: 100%;
}

.subHeader {
  font-family: 'Futura Condensed PT Medium', sans-serif;
  font-weight: 500;
  font-size: calc(.6vw + 16px);
  line-height: 1;
  text-transform: uppercase;
  width: 100%;
}

.mainLabel {
  font-family: 'Futura Condensed PT Medium', sans-serif;
  font-weight: 500;
  font-size: calc(.5vw + 18px);
  line-height: 1;
  text-transform: uppercase;
  width: 100%;
}

.attrHeading {
  font-family: proxima-nova, sans-serif;
  font-weight: 700;
  font-size: 16px;
  line-height: 1;
  text-transform: uppercase;
  width: 100%;
}

.attribute {
  font-family: proxima-nova, sans-serif;
  font-weight: 400;
  font-size: 16px;

  line-height: 1;

  width: 100%;
}

@font-face {
  font-family: 'Futura Condensed PT Medium';
  font-style: normal;
  font-weight: normal;
  src: local('Futura Condensed PT Medium'), url('./fonts/futura-condensed-pt/futura-condensed-pt-medium-589e44ed1e3a5.woff') format('woff');
}


@font-face {
  font-family: 'Futura Condensed PT Medium Oblique';
  font-style: normal;
  font-weight: normal;
  src: local('Futura Condensed PT Medium Oblique'), url('./fonts/futura-condensed-pt/futura-condensed-pt-medium-oblique-589e4507d391c.woff') format('woff');
}


@font-face {
  font-family: 'Futura PT Book';
  font-style: normal;
  font-weight: normal;
  src: local('Futura PT Book'), url('./fonts/futura-condensed-pt/futura-pt-book-589a6dec272c3.woff') format('woff');
}


@font-face {
  font-family: 'Futura PT Book Oblique';
  font-style: normal;
  font-weight: normal;
  src: local('Futura PT Book Oblique'), url('./fonts/futura-condensed-pt/futura-pt-book-oblique-589e44623c7b4.woff') format('woff');
}


@font-face {
  font-family: 'Futura PT Light';
  font-style: normal;
  font-weight: normal;
  src: local('Futura PT Light'), url('./fonts/futura-condensed-pt/futura-pt-light-589a6e187563a.woff') format('woff');
}


@font-face {
  font-family: 'Futura PT Light Oblique';
  font-style: normal;
  font-weight: normal;
  src: local('Futura PT Light Oblique'), url('./fonts/futura-condensed-pt/futura-pt-light-oblique-589e448ae90e2.woff') format('woff');
}


@font-face {
  font-family: 'Futura PT Medium';
  font-style: normal;
  font-weight: normal;
  src: local('Futura PT Medium'), url('./fonts/futura-condensed-pt/futura-pt-medium-589e45b956de4.woff') format('woff');
}


@font-face {
  font-family: 'Futura PT Medium Oblique';
  font-style: normal;
  font-weight: normal;
  src: local('Futura PT Medium Oblique'), url('./fonts/futura-condensed-pt/futura-pt-medium-oblique-589e460871ec2.woff') format('woff');
}


@font-face {
  font-family: 'Futura PT DemiBold';
  font-style: normal;
  font-weight: normal;
  src: local('Futura PT DemiBold'), url('./fonts/futura-condensed-pt/futura-pt-demibold-589e43b852117.woff') format('woff');
}


@font-face {
  font-family: 'Futura PT DemiBold Oblique';
  font-style: normal;
  font-weight: normal;
  src: local('Futura PT DemiBold Oblique'), url('./fonts/futura-condensed-pt/futura-pt-demibold-oblique-589e43ec3ea82.woff') format('woff');
}


@font-face {
  font-family: 'Futura PT Bold';
  font-style: normal;
  font-weight: normal;
  src: local('Futura PT Bold'), url('./fonts/futura-condensed-pt/futura-pt-bold-589e44b6aacd3.woff') format('woff');
}


@font-face {
  font-family: 'Futura PT Bold Oblique';
  font-style: normal;
  font-weight: normal;
  src: local('Futura PT Bold Oblique'), url('./fonts/futura-condensed-pt/futura-pt-bold-oblique-589e453384a18.woff') format('woff');
}


@font-face {
  font-family: 'Futura PT Heavy';
  font-style: normal;
  font-weight: normal;
  src: local('Futura PT Heavy'), url('./fonts/futura-condensed-pt/futura-pt-heavy-589a6dd12187e.woff') format('woff');
}


@font-face {
  font-family: 'Futura PT Heavy Oblique';
  font-style: normal;
  font-weight: normal;
  src: local('Futura PT Heavy Oblique'), url('./fonts/futura-condensed-pt/futura-pt-heavy-oblique-589a6e480ab16.woff') format('woff');
}
</style>