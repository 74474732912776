<template>
      <v-container fluid v-if="loaded">
        <Header></Header>
        <v-row  >
        <v-col lg="6" md="6" sm="6" xs="12">
          <h2 class="">{{release.title}}</h2>
          <div class="dateHeader">Release Date:{{release['release-date']}}</div>
          <div class="mt-8" style=" border-top: 1px solid #aaaaaa;;" > </div>
        </v-col>
        <v-col lg="6" md="6" sm="6" xs="12">
          <div style="margin-top:-80px; width:100%;max-width: 350px" class="">
            <AmplienceImage :src="this.release['cover-image']"></AmplienceImage>
          </div>
        </v-col>
      </v-row>
        <v-row class="ma-0 pa-0" >
          <v-col lg="6" md="6" sm="12" xs="12">
            <div  class="ma-0 pa-0" v-for="(setlist, i) in setlists" :key="i">
              <SetList style="width:100%" class="ma-0 pa-0" :setlist="setlist"></SetList>
            </div>
          </v-col>
          <v-col lg="6" md="6" sm="12" xs="12">
            <div class="d-flex flex-column mb-6">
              <div class="notes">
                <div class="attrHeading">Liner notes</div>
                <VueMarkdown>{{release.notes }}</VueMarkdown>
              </div>
              <h2 style="width:100%" class="pl-4 pt-6" v-if="Boolean(release['videos-list'])">VIDEOS</h2>
              <VideoGallery v-if="Boolean(release['videos-list'])" :videos="videos"></VideoGallery>
            </div>
          </v-col>
        </v-row>
      </v-container>

</template>

<script>

/* eslint-disable */
import edgeDancerApi from '../edgedancer';
import VueMarkdown from 'vue-markdown'
import AmplienceImage from './AmplienceImage'
import utils from '../utils'
import SetList from './SetList'
import ImageGallery from './ImageGallery'
import lightboxGallery from './lightboxGallery'
import VideoGallery from './VideoGallery'
import youtube from "@/components/youtube";
import { mapActions } from 'vuex'
import Header from './Header'
export default {
  "name": 'ReleaseDetail',
  "props": ['id'],
  "data": () => ({
    "release": {},
    "setlLists": [],
    "realeaseType":{},
    "coverImageSrc":"",
    "loaded":false
  }),
  "components": {Header, youtube,VideoGallery,lightboxGallery, VueMarkdown, AmplienceImage,SetList},
  "mounted": async function () {
    try {
      await this.fetchLocations(2);
      const references = await Promise.all([
        edgeDancerApi.getContentItem(this.id),
        edgeDancerApi.getChildNodes(this.id),
         ])
      this.release = references[0].body;
      this.setlists = references[1].data.responses;
      this.coverImageSrc = this.release["cover-image"] && utils.amplienceImageUrl(this.release["cover-image"],400)
      this.loaded=true
    } catch
        (e) {
      console.error(e)
    }
  },
  "methods": {
    ...mapActions([
      'fetchLocations'
    ])
  },
  "computed": {
    associatedImages: function () {
      const images = [];
      if(Boolean(this.release.images)) {
        for (let i = 0; i < this.release.images.length; i++) {
          const src = this.release.images[i].image;
          const imageSrc = utils.amplienceImageUrl(src, 800)
          images.push(imageSrc);
        }
      return images
    }},
    videos: function(){
      if (this.release["videos-list"].length>1) {
        return this.release["videos-list"].slice(1)
      }else
        return []
    },
    heroVideo: function(){
      if (this.release["videos-list"].length>0) {
        return this.release["videos-list"][0]
      }else
        return false
    }
  }
}

</script>

<style scoped>


.pastDate {
  font-family: proxima-nova, sans-serif;
  font-size: calc(.7vw + 16px);
  font-weight: 700;
  text-transform: uppercase;

}

.dateHeader {
  font-family: 'Futura Condensed PT Medium',sans-serif;
  font-weight: 500;
  font-size: calc(.6vw + 16px);
  color:#aaaaaa;
  line-height: 1;
  text-transform: uppercase;
  width: 100%;
}
.notes {
  margin-top: 5px;
  font-size: 14px
}

.performanceList {
  font-size: 14px;
  font-weight: 700;
  text-align: left;
  text-transform: uppercase;
}
</style>