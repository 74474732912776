/* eslint-disable */
import Vue from 'vue'
import Router from 'vue-router'
import PerformanceDetail from '@/components/PerformanceDetail'
import SongDetail from '@/components/SongDetail'
import ToursLister from "@/components/ToursLister";
import ReleaseDetail from '@/components/ReleaseDetail'
import ReleaseLister from '@/components/ReleaseLister'
import SongLister from '@/components/SongLister'
import FuturePerformanceDetail from '@/components/FuturePerformanceDetail'
import store from '@/store';
import edgeDancerApi from "@/edgedancer";
import edgedancer from "@/edgedancer";  //or use a full path to ./store
let routes=[
  { path: '/ToursLister/:id',
    name:'ToursLister',
    component:ToursLister,
    props: true},
  { path: '/performanceDetail/:id',
    name: 'PerformanceDetail',
    component: PerformanceDetail,
    props:true},
  { path: '/SongDetail/:id',
    name:'SongDetail',
    component:SongDetail,
    props: true},
  { path: '/ReleaseDetail/:id',
    name:'ReleaseDetail',
    component:ReleaseDetail,
    props: true},
  { path: '/ReleaseLister/:id',
    name:'ReleaseLister',
    component:ReleaseLister,
    props: true},
  { path: '/SongLister/:id',
    name:'SongLister',
    component:SongLister,
    props: true},
  { path: '/FuturePerformanceDetail/:id',
    name:'FuturePerformanceDetail',
    component:FuturePerformanceDetail,
    props: true}
]




Vue.use(Router)
export default new Router({
  mode: 'history',
  base: __dirname,
  routes: routes
})

function getPageRoutes(){

}