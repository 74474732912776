
<template>
  <div >
    <lightbox :items="imagelist"></lightbox>
  </div>
</template>

<script>
/* eslint-disable */
import lightbox from "@/components/lightbox/lightbox";

import "@/components/lightbox/lightbox.css";

export default {

  data: function () {
    return {
      index: null
    };
  },
  props:{
    'imagelist':{type:Array},
    'heading':{type:String},
    'headlineClass':{type:String,'default':'headline'}},
  computed: {
    getOptions: function () {
      return {}
    }
  }
,
  components:{lightbox},
  methods: {
    getCellClass(index, image) {
      if(Number(index) == 0){
        return 'hero-image'
      }else{
        return 'image'
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

  .full-width-block {
    border: 0;
    padding-top: 70px;
    padding-bottom: 70px;
    margin: 0;
    width: 100%;

    overflow: auto;
  }
  .image {
    float: right;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    margin: 1%;
    width: 31%;
    height: 15vw;
  }
  .temp{
    opacity:02
  }
  .hero-image {
    float: left;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    width: 62%;
    height: 34vw;
  }
  /* iPads (portrait and landscape) ----------- */
  @media only screen and (max-width: 800px)   {
    .hero-image {
      width: 94%;
      height: 50vw;
    }
  }
</style>
