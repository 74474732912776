
<template>

    <img width="100%" :src="`https://${src.defaultHost}/i/${src.endpoint}/${encodeURIComponent(src.name )}?w=${w}&fmt=auto`" alt=""/>

</template>
<script>
/* eslint-disable */

  export default {
    props:['src','srcsets','w']
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
img{
  vertical-align:top
}
</style>
