<template>
  <v-container fluid>
    <Header></Header>
    <v-row align="center"
           justify="center" >
      <v-col cols="12" lg="8" md="10" sm="12" class="pa-0" offset-lg="2" offset-md="1">
        <div  class="" style="display: flex;justify-content: center;align-content: center;flex-direction: column;height:45px;width:80px;float:left">Releases</div>
        <div style="width:200px;float:left"><v-select
              class="d-flex align-center"
              v-model="selectedType"
              :items="typesToDisplay"
              label="all"
              @change="selectType"
              item-value="id"
              item-text="value"
              solo
              return-object
          ></v-select> </div>
      </v-col>
      <v-spacer></v-spacer>
    </v-row>
    <v-container fluid class="">
      <v-row>
        <v-col cols="12"  lg="8" md="10" sm="12" class="pa-0" offset-lg="2" offset-mg="1">
      <div :style="'width:'+width+';float:left'" v-for="(item, i) in releasesToDisplay" class="releaseItem pa-sm-3 pa-lg-6 pa-md-6" :key="i">
        <router-link style="text-decoration: none;color:black" :to="{ name: 'ReleaseDetail', params: { id:  item.id}}">

          <div class="itemWrapper">
            <div class="item">
              <amplienceImage :src="item.coverImage" h="250"></amplienceImage>
              <div class="hoverOverlay pa-sm-3 pa-lg-6 pa-md-6">
                <div class="overlayText text-center"> {{item.year }}<br/>{{item.title}}</div>
              </div>
            </div>
          </div>

        </router-link>
      </div>
        </v-col>
        <v-spacer></v-spacer>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>

/* eslint-disable */
import edgeDancerApi from '../edgedancer';
import VueMarkdown from 'vue-markdown'
import AmplienceImage from './AmplienceImage'
import utils from '../utils'
import SetList from './SetList'
import ImageGallery from './ImageGallery'
import lightboxGallery from './lightboxGallery'
import VideoGallery from './VideoGallery'
import youtube from "@/components/youtube";
import {mapActions} from 'vuex'
import Header from './Header'
import { contentBaseUri } from '../../config/cms.json'

export default {
  "name": 'ReleasesLister',
  "props": ['id'],
  "data": () => ({
    "selectedType": {id:"all",value:"all"},
    "releaseTypes": [],
    "allReleases": [],
    "releasesToDisplay": [],
    "typesToDisplay": [],
    "loaded": false
  }),
  "components": {Header, lightboxGallery, VueMarkdown, AmplienceImage, SetList},
  "mounted": async function () {
    try {
      await this.fetchLocations(2);
      const results = await Promise.all([
        edgeDancerApi.getAllContentByType(`${contentBaseUri}/bands/release`),
        edgeDancerApi.getAllContentByType(`${contentBaseUri}/bands/music-releases`),
      ])
      this.releaseTypes = results[1].data.responses;
      this.allReleases = this.mapReleasesToTypes(this.releaseTypes, results[0].data.responses)
      this.releasesToDisplay = this.allReleases.slice(0, 32)
      this.selectedType = {id: "all", type: "all"}
      this.typesToDisplay = this.prepareTypesForDisplay(this.releaseTypes)
      this.loaded = true
    } catch
        (e) {
      console.error(e)
    }
  },
  "methods": {
    ...mapActions([
      'fetchLocations'
    ]),
    performanceDate: function (performance) {
      return utils.formatDate(performance.date, 'MMMM, D, YYYY')
    },
    mapReleasesToTypes: function (types, releases) {
      let releaseMappings = [];
      for (let i = 0; i < releases.length; i++) {
        const releaseType = types.filter(function (item) {
          return item.content['_meta'].deliveryId === releases[i].content['_meta'].hierarchy.parentId;
          ;
        });
        if (Boolean(releaseType.length > 0)) {
          const release = {
            "title":releases[i].content.title,
            id: utils.getCid(releases[i].content),
            content: releases[i].content,
            releaseTypeId: utils.getCid(releaseType[0].content),
            releaseTypeContent: releaseType[0].content,
            coverImage: releases[i].content["cover-image"],
            year: utils.formatDate(releases[i].content["release-date"], "YYYY"),
            date: releases[i].content["release-date"]
          };
          releaseMappings.push(release);
        }
      }
      releaseMappings = releaseMappings.sort(utils.sortDatesDesc)
      return releaseMappings;
    },
    prepareTypesForDisplay: function(types){
      let displayTypes = [];
      for (let i = 0; i < types.length; i++) {
        displayTypes.push({
          id: utils.getCid(types[i].content),
          value: types[i].content["release-label"],
        });
      }
      displayTypes.unshift({id: "all",value: "all"});
      return displayTypes;
    },
    selectType: function () {
      const selectedTypeId = this.selectedType.id
      if (selectedTypeId == "all") {
        this.releasesToDisplay = this.allReleases.slice(0, 32)
      } else {
        let releases = this.allReleases.filter(function (item) {
          return item.releaseTypeId === selectedTypeId;
        });
        this.releasesToDisplay =releases
      }
    },

  },
  "computed": {
    width() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return '50%'
        case 'sm':
          return '50%'
        case 'md':
          return '25%'
        case 'lg':
          return '25%'
        case 'xl':
          return '25%'
      }
    }
  }
}

</script>

<style scoped>


.performanceListYear {
  font-family: 'Futura PT Book', sans-serif;
  font-size: 50px;
  font-weight: 500;
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  opacity: 0.10;
  padding: 0;
  line-height: 0.5;
  top: -25px;
  left: -25px;
  width: 60px;
  height: 100%;
}

.releaseItem {
  position: relative;
  width: 25%;
  float: left
}

.hoverOverlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(181, 37, 50, 0.9);
  color: #fff;
  visibility: hidden;
  opacity: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.item {
  height: 250px;
  width: 250px;
  background-color: #eee;
}
.itemWrapper {
  position: relative;
  width: 100%;
  height: 100%
}

.itemWrapper:hover .hoverOverlay {
  visibility: visible;
  opacity: 1;
}

.performanceList {
  font-size: 14px;
  font-weight: 700;
  text-align: left;
  text-transform: uppercase;
}
</style>