<template>
      <v-container fluid v-if="loaded">
        <Header class="ma-0" :image="(Boolean(pageDetail.masthead))?pageDetail.masthead:null"></Header>
        <TourTable :id="id"
                   :eventsToDisplay="this.events"
                   :header="performanceList.tableHeader"
                   :backgroundImage="performanceList.backgroundImage"
        ></TourTable>
        <v-row class="ma-0 pa-0" >
          <v-col lg="12" md="12" sm="12" xs="12">
            <lightboxGallery :imagelist="associatedImages"></lightboxGallery>
          </v-col>
        <v-row class="ma-0 pa-0" >
          <v-col lg="12" md="12" sm="12" xs="12">
            <VideoGallery :videos="videoList"></VideoGallery>
          </v-col>
        </v-row>

      </v-row>
      </v-container>

</template>

<script>

/* eslint-disable */
import edgeDancerApi from '../edgedancer';
import VueMarkdown from 'vue-markdown'
import AmplienceImage from './AmplienceImage'
import utils from '../utils'
import ImageGallery from './ImageGallery'
import lightboxGallery from './lightboxGallery'
import VideoGallery from './VideoGallery'
import youtube from "@/components/youtube";
import { mapActions } from 'vuex'
import Header from './Header'
import TourTable from './TourTable'
import { contentBaseUri } from '../../config/cms.json'
export default {
  "name": 'ReleaseDetail',
  "props": [],
  "data": () => ({
    "pageDetail": {},
    "dcComponents": [],
    "loaded":false,
    "events":[],
    "id":"future",
    "performanceList":{},
    "videoList":{},
    "imagelist":[]
  }),
  "components": {TourTable, Header, youtube,VideoGallery,lightboxGallery, VueMarkdown, AmplienceImage},
  "mounted": async function () {
    try {
      await this.fetchLocations(2);
      this.pageDetail = this.$store.getters.LandingPage(this.$route.name)
      const components = await edgeDancerApi.getChildNodes(utils.getCid(this.pageDetail))
      if(Boolean(components)) {
        this.dcComponents = components.data.responses;
      }
      this.imagelist=this.dcComponents.find(function(component){
        return component.content['_meta'].schema==`${contentBaseUri}/components/image-gallery`
      }).content.images
      this.videoList=this.dcComponents.find(function(component){
        return component.content['_meta'].schema==`${contentBaseUri}/components/video-gallery`
      }).content["videos-list"]
      const performanceList = this.dcComponents.find(function(component){
        return component.content['_meta'].schema==`${contentBaseUri}/components/tour-table`
      })
      this.performanceList=performanceList.content;
      this.events=this.prepareEventForDisplay(performanceList.content.tourList)
      this.loaded=true
    } catch
        (e) {
      console.error(e)
    }
  },
  "methods": {
    ...mapActions([
      'fetchLocations'
    ]),
    prepareEventForDisplay: function (events) {
      const displayEvents = [];
      let displayYear = "";
      for (let i = 0; i < events.length; i++) {
        let displayEvent = {
          id: edgeDancerApi.deliveryId(events[i]),
          date: utils.formatDate(events[i].date, "MMM DD, YYYY"),
          shortDateY: utils.formatDate(events[i].date, "YYYY"),
          shortDateDM: utils.formatDate(events[i].date, "MMM DD"),
          venue: events[i].venue,
          fullLocation: this.$store.getters.location(events[i].location[0]["_meta"].deliveryId),
        };
        displayEvents.push(displayEvent);
      }
      return displayEvents;
    }
  },
  "computed": {
    associatedImages: function () {
      const images = [];
      if(Boolean(this.imagelist)) {
        for (let i = 0; i < this.imagelist.length; i++) {
          const src = this.imagelist[i].image;
          const imageSrc = utils.amplienceImageUrl(src, 800)
          images.push(imageSrc);
        }
      return images
    }},
    videos: function(){
      if (this.release["videos-list"].length>1) {
        return this.release["videos-list"].slice(1)
      }else
        return []
    },
    heroVideo: function(){
      if (this.release["videos-list"].length>0) {
        return this.release["videos-list"][0]
      }else
        return false
    },
    tourList: function(){
      const performanceList = this.dcComponents.find(function(component){
        return component.content['_meta'].schema==`${contentBaseUri}/components/tour-table`
      })
      this.performanceList=performanceList;
      return this.prepareEventForDisplay(performanceList.content.tourList)
    }
  }
}

</script>

<style scoped>


.pastDate {
  font-family: proxima-nova, sans-serif;
  font-size: calc(.7vw + 16px);
  font-weight: 700;
  text-transform: uppercase;

}

.dateHeader {
  font-family: 'Futura Condensed PT Medium',sans-serif;
  font-weight: 500;
  font-size: calc(.6vw + 16px);
  color:#aaaaaa;
  line-height: 1;
  text-transform: uppercase;
  width: 100%;
}
.notes {
  margin-top: 5px;
  font-size: 14px
}

.performanceList {
  font-size: 14px;
  font-weight: 700;
  text-align: left;
  text-transform: uppercase;
}
</style>