import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify';
import store from './store'
/* eslint-disable */
import '@morioh/v-lightbox/dist/lightbox.css';
import Lightbox from '@morioh/v-lightbox'
import edgeDancerApi from "@/edgedancer";
import LandingPage1 from "./components/LandingPage1"
import { contentBaseUri } from "../config/cms.json"

Vue.use(Lightbox);

new Vue({
    vuetify,
    store,
    router,
    render: h => h(App),
    "mounted": async function () {
        const pages = await edgeDancerApi.getAllContentByType(
            `${contentBaseUri}/site/landing-page`)
        this.$store.commit("setLandingPages",pages.data.responses)
        for (let i = 0; i < pages.data.responses.length; i++) {
            const page= pages.data.responses[i].content
            const route={
                path:page.routePath,
                name:page.routeName,
                component: LandingPage1,
                props:true
            }
            this.$router.addRoutes([route])
        }
    }
}).$mount('#app')


