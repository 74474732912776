<template>
  <v-container fluid v-if="loaded">
    <Header></Header>
    <v-row>
      <v-spacer></v-spacer>
      <v-col lg="6" md="6" sm="12" class="ma-0 pa-0">
        <div style="text-align: center" v-if="Boolean(performance.fullLocation.state)"><h2>
          {{ performance.fullLocation.city }}, {{ performance.fullLocation.state }}, {{
            performance.fullLocation.country
          }}</h2>
        </div>
        <div style="text-align: center" v-else><h2 class="">{{ performance.fullLocation.city }},
          {{ performance.fullLocation.country }}</h2></div>
      </v-col>
      <v-spacer></v-spacer>
    </v-row>
    <v-row class="ma-0 pa-0">
      <v-spacer></v-spacer>
      <v-col lg="6" md="6" sm="12" class="ma-0 pa-0">
        <div style="text-align: center" class="subHeader ma-0 pa-0">{{ performance.venue }} /
          {{ performanceDate(performance) }}
        </div>
      </v-col>
      <v-spacer></v-spacer>
    </v-row>
    <v-row class="ma-0 pb-6 pt-12">
      <v-spacer></v-spacer>
      <v-col  cols="12" lg="5" md="6" sm="12" xs="12" class="ma-0 pa-0">
        <div style="border-bottom:  1px solid #999999" class="mainLabel mb-3">Social Media</div>
        <div style="width: 100%;line-height: 1.5" class="attribute">{{ performance.social }}</div>
      </v-col>
      <v-spacer></v-spacer>
    </v-row>
    <v-row class="ma-0 pa-0">
      <v-spacer></v-spacer>
      <v-col  cols="12" lg="5" md="6" sm="12" xs="12" class="ma-0 pa-0">
        <div style="border-bottom:  1px solid #999999" class="mainLabel mb-3">Contests</div>
        <div style="width: 100%;line-height: 1.5" class="attribute">{{ performance.contest }}</div>
      </v-col>
      <v-spacer></v-spacer>
    </v-row>
  </v-container>
</template>

<script>

/* eslint-disable */
import edgeDancerApi from '../edgedancer';
import VueMarkdown from 'vue-markdown'
import AmplienceImage from './AmplienceImage'
import utils from '../utils'
import SetList from './SetList'
import ImageGallery from './ImageGallery'
import lightboxGallery from './lightboxGallery'
import VideoGallery from './VideoGallery'
import youtube from "@/components/youtube";
import {mapActions} from 'vuex'
import Header from "./Header";
export default {
  "name": 'FuturePerformanceDetails',
  "props": ['id'],
  "data": () => ({
    "performance": {},
    "setlLists": [],
    "tour": {},
    "loaded": false
  }),
  "components": {Header,youtube, VideoGallery, lightboxGallery, VueMarkdown, AmplienceImage, SetList},
  "mounted": async function () {
    try {
      await this.fetchLocations(2);
      const references = await Promise.all([
        edgeDancerApi.getContentItem(this.id)
      ])
      this.performance = references[0].body;
      this.performance['fullLocation'] = this.$store.getters.location(utils.getCid(this.performance.location[0]))
      const parent = await edgeDancerApi.getParent(this.performance)

      this.tour = parent.body
      this.loaded = true
    } catch
        (e) {
      console.error(e)
    }
  },
  "methods": {
    ...mapActions([
      'fetchLocations'
    ]),
    performanceDate: function (performance) {
      return utils.formatDate(performance.date, 'MMMM, D, YYYY')
    }
  },
  "computed": {
    associatedImages: function () {
      const images = [];
      if (Boolean(this.performance.images)) {
        for (let i = 0; i < this.performance.images.length; i++) {
          const src = this.performance.images[i].image;
          const imageSrc = utils.amplienceImageUrl(src, 800)
          images.push(imageSrc);
        }
        return images
      }
    },
    videos: function () {
      if (this.performance["videos-list"].length > 1) {
        return this.performance["videos-list"].slice(1)
      } else
        return []
    },
    heroVideo: function () {
      if (this.performance["videos-list"].length > 0) {
        return this.performance["videos-list"][0]
      } else
        return false
    }
  }
}

</script>

<style scoped>


.pastDate {
  font-family: proxima-nova, sans-serif;
  font-size: calc(1vw + 10px);
  font-weight: 700;
  text-transform: uppercase;
}

.pastCity {
  font-family: proxima-nova, sans-serif;
  margin-top: 5px;
  font-size: calc(.9vw);
  font-weight: 600;
  text-transform: uppercase;
}

.lyrics {
  margin-top: 5px;
  font-size: 14px
}

.perfTotal {
  font-size: 72px;
  font-weight: 700;
  text-align: left;
  line-height: 1;
}

.performanceList {
  font-size: 14px;
  font-weight: 700;
  text-align: left;
  text-transform: uppercase;
}
</style>