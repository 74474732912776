<template>
  <div style="width:100% ">
    <h2 style="width:100%" class="pl-4  pt-6">APPEARED ON THESE RELEASES</h2>
    <div :style="'width:'+width+';float:left'" class="release pa-3" v-for="(release, i) in releases" :key=i.title>
      <router-link style="text-decoration: none;color:black" :to="{ name: 'ReleaseDetail', params: { id:  release['_meta'].deliveryId}}">
        <AmplienceImage :src="release['cover-image']" w="100"></AmplienceImage>
      </router-link>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import edgeDancerApi from '../edgedancer';
import AmplienceImage from './AmplienceImage'
import moment from 'moment'
export default {
  name: 'SongReleases',
  props: ['releases'],
  data: () => ({

  }),
  components: {AmplienceImage},
  mounted: async function () {
    try {
      console.log("rsong eleases created");

      console.log(this.releases);
    } catch
        (e) {
      console.error(e)
    }
  }
  ,
  methods: {


  },
  computed: {
    width () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return '50%'
        case 'sm': return '50%'
        case 'md': return '25%'
        case 'lg': return '25%'
        case 'xl': return '25%'
      }
    },
  }

}

</script>

<style scoped>

.pastDate {
  font-family: proxima-nova, sans-serif;
  font-size: calc(1vw + 10px);
  font-weight: 700;
  text-transform: uppercase;
}

.pastCity {
  font-family: proxima-nova, sans-serif;
  margin-top: 5px;
  font-size: calc(.9vw);
  font-weight: 600;
  text-transform: uppercase;
}

.perfTotal {
  font-size: 72px;
  font-weight: 700;
  text-align: left;
  line-height: 1;
}

.performanceList {
  font-size: 14px;
  font-weight: 700;
  text-align: left;
  text-transform: uppercase;
}
</style>